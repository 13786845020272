<template>
    <div>
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Other Fees</h4>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'Student-Affairs' }">Student Affairs</router-link>
                    </li>
                    <li class="breadcrumb-item" v-if="student.id">
                        <router-link
                            :to="{ name: 'Student-Affairs-Other-Fees-Student', params: { student_id: student.id } }">
                            OtherFees
                        </router-link>
                    </li>
                    <li class="breadcrumb-item active">Edit</li>
                </ol>
            </div>
        </div>

        <div class="row tab-content">
            <div id="" class="tab-pane fade active show col-md-9">
                <div class="card top-red-border">
                    <div class="card-header">
                        <h4 class="card-title">Edit Other Fees: {{ student.firstname }} {{ student.middlename }} {{
                                student.lastname
                        }}</h4>
                    </div>
                    <div class="card-body">
                        <!-- FeedBack -->
                        <feedback-messages :msgs="msgs" :errors="errors" />
                        <!-- /FeedBack -->
                        <div class="basic-form">
                            <form id="other_fees_create" @submit.prevent="submitFormData()">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Payment Profiles <span
                                            style="color: red">*</span></label>
                                    <div class="col-md-9">
                                        <select class="form-control" name="payment_profile_id" required
                                            v-model="other_fee.payment_profile_id" @change="filterInstallments($event)">
                                            <option value="" disabled selected>
                                                Nothing Selected
                                            </option>
                                            <option v-for="profile in lists.payment_profiles" :key="profile.id"
                                                :value="profile.id">
                                                {{ profile.service.value }} ({{ profile.year.value }})
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">
                                        Installments
                                        <span style="color: red">*</span>
                                    </label>
                                    <div class="col-md-9">
                                        <select class="form-control" name="installment_id" required
                                            v-model="other_fee.installment_id">
                                            <option value="" disabled selected>
                                                Nothing Selected
                                            </option>
                                            <option v-for="installment in filtered_installments" :key="installment.id"
                                                :value="installment.id">
                                                Amount: {{ installment.amount }}, Date
                                                {{ installment.start_date }} To
                                                {{ installment.start_date }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">
                                        Type
                                        <span style="color: red">*</span>
                                    </label>
                                    <div class="col-md-9">
                                        <select class="form-control" name="type" required v-model="other_fee.type">
                                            <option value="" disabled selected>
                                                Nothing Selected
                                            </option>
                                            <option value="Add">Add</option>
                                            <option value="Deduct">Deduct</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">
                                        Amount
                                        <span style="color: red">*</span>
                                    </label>
                                    <div class="col-md-9">
                                        <input type="number" class="form-control" name="amount" required
                                            v-model="other_fee.amount" />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">
                                        Reason
                                        <span style="color: red">*</span>
                                    </label>
                                    <div class="col-md-9">
                                        <textarea class="form-control" name="reason"
                                            v-model="other_fee.reason"></textarea>
                                    </div>
                                </div>

                                <div class="form-group row" style=" padding-top: 2rem; margin-bottom: 0px; ">
                                    <div class="col-md-12">
                                        <button :disabled="disableBtn" name="submit" type="submit"
                                            class="btn btn-primary" value="Submit" onclick="this.blur();"> Submit
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
  import FeedbackMessages from "@/components/FeedbackMessages.vue";
  import useOtherFees from "@/composables/other_fees";
  import { inject, onBeforeMount, ref } from "vue";
  import { useRouter } from "vue-router";
  export default {
      props: {
          id: { //oter fees id
              required: true,
              type: Number,
          },
      },
      components: {
          FeedbackMessages,
      },
      setup(props) {
          const disableBtn = ref(false);
          const filtered_installments = ref([]);
          const swal = inject("$swal");
          const hasPermission = inject("hasPermission");
          const router = useRouter();
  
          const { updateOtherFee, getOtherFee, student, other_fee, lists, msgs, errors } = useOtherFees();
  
          onBeforeMount(async () => {
              //first: check the user Permission
              if (!hasPermission("edit_other_fees"))
                  router.push({
                      name: "401",
                  });
              await getOtherFee(props.id);
              filtered_installments.value = lists.installments;
          });
  
          const filterInstallments = (event) => {
              var payment_profile_id = event.target.value;
  
              filtered_installments.value = lists.installments.filter(
                  (installment) => {
                      if (installment.payment_profile_id == payment_profile_id)
                          return installment;
                  }
              );
          };
  
          const submitFormData = async () => {
              var formDataObj = new FormData();
              const form = document.querySelector("#other_fees_create");
              Array.from(form.elements).forEach((input) => {
                  formDataObj.append(input.name, input.value);
              });
              formDataObj.append('student_id', student.value.id);
  
              swal.fire({
                  title: "Please wait while submit your request",
                  allowOutsideClick: false,
                  didOpen: () => {
                      swal.showLoading();
                  },
              });
  
              errors.value = [];
              disableBtn.value = true;
  
              await updateOtherFee(formDataObj, props.id).then((resp) => {
                  swal.close();
                  if (resp != "fail") {
                    //:to="{ name: 'Student-Affairs-Other-Fees-Student', params: { student_id: student.id } }"
                      router.push({
                          name: "Student-Affairs-Other-Fees-Student",
                          params: {
                            student_id: student.value.id,
                          },
                      });
                  }
                  window.scrollTo(0, 0);
                  disableBtn.value = false;
              });
          };
  
          return {
              submitFormData,
              filterInstallments,
              filtered_installments,
              other_fee,
              lists,
              student,
              disableBtn,
              msgs,
              errors,
          };
      },
  };
  </script>
  